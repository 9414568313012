import React from 'react'

class App extends React.Component
{
    render() {
        console.log("Rendering App")
        return (
            <h1>Coding In Progress...</h1>
        )
    }
}

export default App;